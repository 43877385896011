import React, { useEffect, useState } from 'react';
import { ReminderInput } from './ReminderInput';
import { SelectTemplates } from './SelectTemplates';
import { UserSelection } from './UserSelection';

export const ReminderSubSection = ({
    templates,
    value,
    formik,
    templateValue,
    userValue,
    tabChange,
    plainTextContent,
}: any) => {
    // const [selectedUser, setSelectedUser] = useState('Trainer');
    // const [selectedTemplate, setSelectedTemplate] = useState('');
    const { selectedUser, setSelectedUser } = userValue;
    const { selectedTemplate, setSelectedTemplate } = templateValue;
    const isTrainer = selectedUser === 'Trainer';
    const isReminder = value === 'Reminder';
    const isUpcoming = value === 'UpcomingTraining';
    const isNotification = value === 'Notification';
    const isEmail = value === 'Email';
    const isTrainerAndReminder = isTrainer && isReminder;
    const isTraineeAndReminder = !isTrainer && isReminder;
    const isTrainerAndEmail = isTrainer && isEmail;
    const isTraineeAndEmail = !isTrainer && isEmail;
    const isTrainerAndUpcoming = isTrainer && isUpcoming;
    const isTraineeAndUpcoming = !isTrainer && isUpcoming;
    const isTrainerAndNotification = isTrainer && isNotification;
    const isTraineeAndNotification = !isTrainer && isNotification;
    const isAll =
        isTrainerAndNotification ||
        isTraineeAndNotification ||
        isTraineeAndEmail ||
        isTrainerAndEmail;

    const arraySpreadFunc = (condition: boolean, template: any) => {
        return { ...(condition && template) };
    };

    const templateTabs = [
        arraySpreadFunc(isReminder || isNotification, {
            displayText: 'Reminder Emails (RE)',
            value: 'RE',
        }),
        arraySpreadFunc(isAll, {
            displayText: 'Book Now Confirmation (BNC)',
            value: 'BNC',
        }),
        arraySpreadFunc(isAll, {
            displayText: 'Customize Acceptance (CA)',
            value: 'CA',
        }),
        arraySpreadFunc(isAll, {
            displayText: 'Customize Rejection (CR)',
            value: 'CR',
        }),
        arraySpreadFunc(isAll, {
            displayText: 'Payment Done (PD)',
            value: 'PD',
        }),
        arraySpreadFunc(!isTrainer && isEmail, {
            displayText: 'Feedback Request (FR)',
            value: 'FR',
        }),
        arraySpreadFunc(isTrainer && !isReminder, {
            displayText: 'Customize Notification (CN)',
            value: 'CN',
        }),
        arraySpreadFunc(isTrainer && !isReminder, {
            displayText: 'New Bidding Alert (NBA)',
            value: 'NBA',
        }),
        arraySpreadFunc(isTrainer && !isReminder, {
            displayText: 'Bid Accepted (BDA)',
            value: 'BDA',
        }),
        arraySpreadFunc(isTrainer && !isReminder, {
            displayText: 'New Bid Quote (BDQ)',
            value: 'BDQ',
        }),
        arraySpreadFunc(isTrainer && !isReminder, {
            displayText: 'Show Interest Notification (SI)',
            value: 'SI',
        }),
        arraySpreadFunc(isEmail, {
            displayText: 'Show Internship Email (SIE)',
            value: 'ITMM',
        }),
        arraySpreadFunc(isNotification, {
            displayText: 'Show Internship Template (SIT)',
            value: 'ITMM',
        }),
    ];

    return (
        <div>
            <UserSelection
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                tabChange={tabChange}
            />
            <SelectTemplates
                templateTabs={templateTabs}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
            />
            <ReminderInput
                formik={formik}
                value={value}
                selectedTemplate={selectedTemplate}
                plainTextContent={plainTextContent}
            />
        </div>
    );
};
