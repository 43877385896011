import { useAppSelector, useAppDispatch } from '../..';
import { SettingsJson, SettingJSONForCMS } from './SettingJSON';
import { updateRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { setAppConfig } from '../../Redux/Reducers/adminReducers';

export const Settings = () => {
    const dispatch = useAppDispatch();
    const [{ appConfig }] = useAppSelector(({ appConfig }: any) => [appConfig]);

    console.log(appConfig);

    //

    // const modeChange = async (obj: any, isToggle: boolean = true) => {
    //     const postData = {
    //         [obj['name']]: isToggle ? !appConfig[obj['name']] : obj['value'],
    //     };
    //     const originalData = {
    //         [obj['name']]: appConfig[obj['name']],
    //     };
    //     const body = {
    //         id: appConfig['id'],
    //         ...postData,
    //     };
    //     dispatch(setAppConfig(postData));
    //     const setConfig = await updateRecord(body, 'admin/adminconfigs');
    //     if (!setConfig.status) {
    //         dispatch(setAppConfig(originalData));
    //         toast.error(setConfig.message);
    //     }
    // };

    const modeChange = async (obj: any, isToggle: boolean = true) => {
        let value;

        if (isToggle) {
            if (obj['name'] === 'comparability_action') {
                // Toggle between "edit" (on) and "off"
                value = appConfig[obj['name']] === 'edit' ? 'off' : 'edit';
            } else {
                value = !appConfig[obj['name']];
            }
        } else {
            value = obj['value'];
        }

        const postData = {
            [obj['name']]: value,
        };

        const originalData = {
            [obj['name']]: appConfig[obj['name']],
        };

        const body = {
            id: appConfig['id'],
            ...postData,
        };

        dispatch(setAppConfig(postData));
        const setConfig = await updateRecord(body, 'admin/adminconfigs');
        if (!setConfig.status) {
            dispatch(setAppConfig(originalData));
            toast.error(setConfig.message);
        }
    };

    return (
        <div className="w-11/12 space-y-10 mx-auto mt-8">
            <h1 className="text-[18px] font-[600]">Settings</h1>
            {appConfig && (
                <>
                    <ToggleInputFunc
                        appConfig={appConfig}
                        modeChange={modeChange}
                    />
                    <RadioInputFunction
                        modeChange={modeChange}
                        appConfig={appConfig}
                    />
                </>
            )}
        </div>
    );
};

const ToggleInputFunc = ({ modeChange, appConfig }: any) => {
    return (
        <div className="space-y-8 w-11/12 lg:w-10/12 xl:w-9/12">
            {SettingsJson.map((setting, index) => {
                console.log('settingggggggg', setting);

                return (
                    <div key={index} className="flex flex-col space-y-2">
                        <div className="flex justify-between">
                            <div className="flex gap-2">
                                <img
                                    src={setting.img}
                                    alt="Settings"
                                    className="w-[50px] xl:w-[55px] h-[50px] xl:h-[55px]"
                                />
                                <div className="flex flex-col space-y-2 xl:space-y-1">
                                    <h1 className="text-[14px] xl:text-[16px] font-[500]">
                                        {setting.title}
                                    </h1>
                                    <p className="text-[#848484] text-[10px] xl:text-[12px] font-[400]">
                                        {setting.description}
                                    </p>
                                </div>
                            </div>

                            <ToggleButton
                                appConfig={appConfig}
                                setting={setting}
                                modeChange={modeChange}
                            />
                        </div>
                        <div className="border-b-2 mt-2"></div>
                    </div>
                );
            })}
        </div>
    );
};

const RadioInputFunction = ({ modeChange, appConfig }: any) => {
    return (
        <div className="pb-10 space-y-8 w-11/12 lg:w-10/12 xl:w-9/12">
            {SettingJSONForCMS.map((setting, index) => {
                return (
                    <div key={index} className="flex flex-col space-y-2">
                        <div className="flex justify-between">
                            <div className="flex gap-2">
                                <img
                                    src={setting.img}
                                    alt="Settings"
                                    className="w-[50px] xl:w-[55px] h-[50px] xl:h-[55px]"
                                />
                                <div className="flex flex-col space-y-2 xl:space-y-1">
                                    <h1 className="text-[14px] xl:text-[16px] font-[500]">
                                        {setting.title}
                                    </h1>
                                    <p className="text-[#848484] text-[10px] xl:text-[12px] font-[400]">
                                        {setting.description}
                                    </p>
                                </div>
                            </div>
                            <CheckField
                                setting={setting}
                                appConfig={appConfig}
                                modeChange={modeChange}
                            />
                        </div>
                        <div className="border-b-2 mt-2"></div>
                    </div>
                );
            })}
        </div>
    );
};

const CheckField = ({ setting, appConfig, modeChange }: any) => {
    const options = ['View', 'Edit', 'Off'];
    return (
        <>
            {options.map((opt, index) => {
                return (
                    <div
                        key={index}
                        className="flex items-center justify-center gap-2"
                    >
                        <input
                            type="radio"
                            name={setting['name']}
                            checked={
                                appConfig[setting['name']] === opt.toLowerCase()
                            }
                            onChange={() =>
                                modeChange(
                                    {
                                        name: setting['name'],
                                        value: opt.toLowerCase(),
                                    },
                                    false
                                )
                            }
                            className="cursor-pointer w-[15px] h-[15px]"
                            title="radiobutton"
                        />
                        <label className="text-[#848484] text-[14px] font-[500]">
                            {opt}
                        </label>
                    </div>
                );
            })}
        </>
    );
};

// const ToggleButton = ({ appConfig, setting, modeChange }: any) => {
//     // console.log({ appConfig,setting});
//     return (
//         <div className="flex items-center">
//             <label className={'toggle'}>
//                 <input
//                     type="checkbox"
//                     name={setting['name']}
//                     checked={appConfig[setting['name']]}
//                     onChange={() => modeChange(setting)}
//                     title="toggleSwitch"
//                 />
//                 <span className="slider"></span>
//             </label>
//         </div>
//     );
// };
const ToggleButton = ({ appConfig, setting, modeChange }: any) => {
    const isChecked =
        setting.name === 'comparability_action'
            ? appConfig[setting.name] === 'edit'
            : appConfig[setting.name];

    return (
        <div className="flex items-center">
            <label className="toggle">
                <input
                    type="checkbox"
                    name={setting.name}
                    checked={isChecked}
                    onChange={() => modeChange(setting)}
                    title="toggleSwitch"
                />
                <span className="slider"></span>
            </label>
        </div>
    );
};
