import axios from 'axios';

const DigitalOceanProduction =
    'https://production-api-vzdpe.ondigitalocean.app/';

// const backendUrl = 'http://localhost:8080';
// const uatUrl="http://localhost:4000"
// const DigitalOceanUAT = 'https://seashell-app-kk5xt.ondigitalocean.app';

const axiosClient = axios.create({
    baseURL: DigitalOceanProduction,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let res = error.response;
        console.log({ res });
        if (res?.status === 401) {
            NavigateToSignInWithRouteParams(res);
        }
        if (res) {
            return res;
        }
    }
);

export const NavigateToSignInWithRouteParams = (response: any) => {
    // console.log({response});
    const origin = window.location.origin;
    const search = window.location.search;
    sessionStorage.setItem(
        'lastExpiredRoute',
        window.location.pathname + search
    );
    window.location.href = `${origin}/?errormessage=${response.data.message}`;
    // toast.error(response.data.message);
    // const fromRoute = locationHref.split(window.location.host)[1];
    // navigate("/login", { state: { from: fromRoute } });
};

export default axiosClient;
